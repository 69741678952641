/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Container,
  Avatar,
  Typography,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  List,
  ListItem,
  Button,
  CardMedia,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  doc,
  getDoc,
  onSnapshot,
  collection,
  query,
  orderBy,
  where,
  getDocs,
  setDoc,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import HeaderPerfil from "./HeaderPerfil";
import BottomToolbar from "./BottomToolbar";
import CopyToClipboardComponent from "./copy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        display: value === index ? "flex" : "none",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {value === index && (
        <Box sx={{ p: 3, flexGrow: 1, overflow: "auto" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Miperfil = () => {
  let navigate = useNavigate();
  const { uid: profileUid } = useParams();
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [events, setEvents] = useState([]);
  const [following, setFollowing] = useState([]);
  const [followingDetails, setFollowingDetails] = useState([]);

  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    } else {
      const fetchProfile = async () => {
        const docRef = doc(db, "users", profileUid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setProfile(docSnap.data());
        } else {
          console.log("No such document!");
        }
        setLoading(false);
      };

      fetchProfile();
    }
  }, [navigate, profileUid]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        setLoading(true);
        const offersCollectionRef = collection(db, "offers");
        const q = query(
          offersCollectionRef,
          where("uid", "==", profileUid),
          orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const offersData = snapshot.docs.map((doc) => {
            const data = doc.data(); // Access the document data
            let imageUrls = [];
            if (data.images && Array.isArray(data.images)) {
              imageUrls = data.images;
            } else if (data.images && typeof data.images === "string") {
              imageUrls = [data.images];
            }

            console.log("Processed imageUrls:", imageUrls); // Debugging log

            return {
              id: doc.id,
              ...data, // Spread the document data
              imageUrls,
            };
          });
          setLoading(false);
          setOffers(offersData);
          console.log("Ofertas obtenidas:", offersData);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener ofertas:", error);
        setLoading(false);
      }
    };
    fetchOffers();
  }, [profileUid]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const eventsCollection = collection(db, "events");
        const q = query(
          eventsCollection,
          where("uid", "==", profileUid),
          orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const eventsData = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              name: data.name,
              title: data.title,
              description: data.description,
              eventType: data.eventType,
              date: data.date,
              createdAt: data.createdAt,
            };
          });
          setEvents(eventsData);
          setLoading(false);
          console.log("Eventos obtenidos:", eventsData);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener eventos:", error);
        setLoading(false);
      }
    };
    fetchEvents();
  }, [profileUid]);

  useEffect(() => {
    const fetchFollowingDetails = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const userFollowingRef = doc(db, "userFollowing", uid);
            const userFollowingDoc = await getDoc(userFollowingRef);
            
            if (userFollowingDoc.exists()) {
              const followingData = userFollowingDoc.data();
              const followingIds = Object.keys(followingData);

              const followedCompaniesDetails = await Promise.all(
                followingIds.map(async (companyId) => {
                  const companyDoc = await getDoc(doc(db, "users", companyId));
                  if (companyDoc.exists()) {
                    return { id: companyId, ...companyDoc.data() };
                  }
                  return null;
                })
              );

              setFollowingDetails(followedCompaniesDetails.filter(Boolean));
            } else {
              setFollowingDetails([]);
            }
          }
        }
      } catch (error) {
        console.error("Error al obtener detalles de empresas seguidas:", error);
      }
    };

    fetchFollowingDetails();
  }, [following]); 

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const userFollowingRef = doc(db, "userFollowing", uid);
            const userFollowingDoc = await getDoc(userFollowingRef);

            if (userFollowingDoc.exists()) {
              const followingData = userFollowingDoc.data();
              const followingIds = Object.keys(followingData);
              setFollowing(followingIds);
            } else {
              setFollowing([]);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener las empresas seguidas:", error);
        setLoading(false);
      }
    };

    fetchFollowing();
  }, []);

  const handleFollow = async (companyId) => {
    try {
      const isAuthenticated = await isLoggedIn();
      if (isAuthenticated) {
        const { uid } = auth.currentUser || {};
        if (uid) {
          setLoading(true);
          const userFollowingRef = doc(db, "userFollowing", uid);
          const companyFollowersRef = doc(db, "companyFollowers", companyId);

          const isFollowing = following.includes(companyId);

          if (isFollowing) {
            // Dejar de seguir
            await updateDoc(userFollowingRef, {
              [companyId]: deleteField(),
            });
            await updateDoc(companyFollowersRef, {
              [uid]: deleteField(),
            });
            setFollowing(following.filter((id) => id !== companyId));
          } else {
            // Comenzar a seguir
            await setDoc(
              userFollowingRef,
              { [companyId]: true },
              { merge: true }
            );
            await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
            setFollowing([...following, companyId]);
          }
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar el seguimiento de la empresa:", error);
      setLoading(false);
    }
  };

  const isCompanyFollowed = (companyId) => following.includes(companyId);

  const getDomainFromUrl = (url) => {
    // Si la URL no es válida, devuelve la URL original
    if (!url) {
      return url;
    }

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    const domain = new URL(url).hostname;

    // Encuentra el punto "." antes de la extensión
    const dotIndex = domain.lastIndexOf(".");

    // Si no se encuentra un punto, devuelve el dominio completo
    if (dotIndex === -1) {
      return domain;
    }

    // Extrae el dominio hasta el punto "."
    const shortenedDomain = domain.substring(0, dotIndex);

    // Si el dominio es demasiado largo, lo recorta y agrega "..."
    if (shortenedDomain.length > 15) {
      return shortenedDomain.slice(0, 15) + "...";
    }

    // Devuelve el dominio recortado
    return shortenedDomain;
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [attendees, setAttendees] = useState({});
  const [attendeeProfiles, setAttendeeProfiles] = useState({});

  useEffect(() => {
    const fetchAttendees = async () => {
      const attendeesData = {};
      const profilesData = {}; // Objeto para almacenar las imágenes de perfil

      for (const event of events) {
        const eventDocRef = doc(db, "events", event.id);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          const eventData = eventDocSnap.data();
          attendeesData[event.id] = eventData.attendees || [];

          // Obtener las imágenes de perfil de los asistentes
          for (const attendeeId of attendeesData[event.id]) {
            if (!profilesData[attendeeId]) {
              // Evitar consultas duplicadas
              const userDocRef = doc(db, "users", attendeeId);
              const userDocSnap = await getDoc(userDocRef);

              if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                profilesData[attendeeId] = {
                  profileImageUrl: userData.profileImageUrl || "", // Obtener la URL de la imagen de perfil
                };
              }
            }
          }
        }
      }

      setAttendees(attendeesData);
      setAttendeeProfiles(profilesData); // Actualizar el estado con las imágenes de perfil
    };

    fetchAttendees();
  }, [events]);

  const handleAttend = async (eventId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const eventDocRef = doc(db, "events", eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          const eventData = eventDocSnap.data();
          const attendees = eventData.attendees || [];

          if (attendees.includes(user.uid)) {
            const updatedAttendees = attendees.filter(
              (attendeeId) => attendeeId !== user.uid
            );
            await updateDoc(eventDocRef, { attendees: updatedAttendees });
          } else {
            const updatedAttendees = [...attendees, user.uid];
            await updateDoc(eventDocRef, { attendees: updatedAttendees });
          }
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar la asistencia al evento:", error);
    }
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const styles = {
    listItem: {
      display: "block",
      width: "98%",
      border: "0.5px solid #ccc",
      margin: "10px auto",
      boxShadow: "0px 0px 5px 0px black",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "15px",
      marginTop: "15px",
    },
    title: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "15px",
    },
    titlee: {
      fontSize: "20px",
      marginBottom: "15px",
    },
    offer: {
      color: "dodgerblue",
      fontSize: "18px",
      marginBottom: "15px",
    },
    url: {
      marginBottom: "15px",
    },
    description: {
      marginBottom: "15px",
      fontWeight: "300",
    },
    cupon: {
      color: "lightseagreen",
      padding: "3px",
      borderRadius: "3px",
      backgroundColor: "lightgrey",
      fontSize: "20px",
      marginBottom: "15px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "15px",
    },
    media: {
      width: "130px",
      marginTop: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    event: {
      color: "black",
      fontWeight: "500",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
      fontWeight: "400",
      marginBottom: "15px",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      margin: "auto",
      marginBottom: "100px",
    },
    listi: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "15px",
      width: "100%",
      margin: "auto",
      marginBottom: "20px",
      boxShadow: "0px 0px 5px 0px black",
    },
    namee: {
      fontSize: "20px",
      color: "dodgerblue",
      padding: "10px",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },

    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
      }}
    >
      <Box>
        <HeaderPerfil />
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "auto",
          mt: 15,
        }}
      >
        {profile && (
          <>
            <Avatar
              alt={profile.displayName}
              src={profile.profileImageUrl}
              sx={{
                width: 100,
                height: 100,
                mt: 2,
                mb: 2,
                border: "4.5px solid #312783",
              }}
            />
            <Typography variant="h5" component="div">
              {profile.displayName}
            </Typography>
            <Typography variant="body2" color="grey" component="div">
              {profile.email}
            </Typography>
            <Typography variant="body2" color="grey" component="div">
              {profile.phone}
            </Typography>
            <Typography variant="body2" color="grey" component="div">
              {profile.point}
            </Typography>
            {profile && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2, mb: 2 }}
                onClick={handleFollow}
                disabled={loading}
              >
                {following.includes(profileUid) ? "Dejar de seguir" : "Seguir"}
              </Button>
            )}
          </>
        )}
      </Container>

      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="profile tabs"
          variant="fullWidth"
        >
          <Tab label="Ofertas" {...a11yProps(0)} />
          <Tab label="Eventos" {...a11yProps(1)} />
          <Tab label="Seguidos" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <TabPanel value={value} index={0}>
          <Typography variant="h6" component="div" sx={styles.name}>
            Ofertas
          </Typography>
          <List style={styles.list}>
            {offers.map((offerData) => (
              <ListItem key={offerData.id} style={styles.listItem}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  {/* Columna izquierda para imágenes */}
                  <Box
                    sx={{
                      width: { xs: "100%", md: "30%" },
                      mr: { xs: 0, md: 2 },
                      mb: { xs: 2, md: 0 },
                    }}
                  >
                    <Box style={styles.contUser}>
                      <CardMedia
                        component="img"
                        image={offerData.image}
                        title="Perfil"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          marginRight: 16,
                          border: "2.5px solid #312783",
                        }}
                      />
                      <Typography style={styles.name}>
                        {offerData.name}
                      </Typography>
                    </Box>
                    {offerData.imageUrls && offerData.imageUrls.length > 0 ? (
                      <>
                        <Box style={styles.imageContainer}>
                          <CardMedia
                            component="img"
                            image={offerData.images[0]}
                            alt={`Imagen principal de ${offerData.name}`}
                            style={styles.mainImage}
                          />
                          {offerData.images.length > 1 && (
                            <Box
                              style={styles.additionalImagesOverlay}
                              onClick={() =>
                                handleOpenImageModal(offerData.images)
                              }
                            >
                              <Typography style={styles.additionalImagesCount}>
                                +{offerData.images.length - 1}
                              </Typography>
                              <Typography>Ver más</Typography>
                            </Box>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Box style={styles.noImage}>
                        <Typography>No hay imágenes disponibles</Typography>
                      </Box>
                    )}
                  </Box>

                  {/* Columna derecha para el contenido */}
                  <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                    <Typography style={styles.title}>
                      {offerData.product}
                    </Typography>
                    <Typography style={styles.description}>
                      {offerData.description}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      href={`http://${offerData.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ width: "100%", mt: 1, mb: 1 }}
                    >
                      Comprar en {getDomainFromUrl(offerData.url)}
                    </Button>
                    <Typography style={styles.offer}>
                      Descuento: {offerData.offer}%
                    </Typography>
                    <CopyToClipboardComponent textToCopy={offerData.cupon} />
                    <Typography style={styles.created}>
                      Fecha de publicación: {formatDate(offerData.createdAt)}
                    </Typography>
                    {profile && (
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                        onClick={handleFollow}
                        disabled={loading}
                      >
                        {following.includes(profileUid)
                          ? "Dejar de seguir"
                          : "Seguir"}
                      </Button>
                    )}
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" component="div" sx={styles.name}>
            Eventos
          </Typography>
          <List style={styles.list}>
            {events.map((eventData) => (
              <ListItem key={eventData.id} style={styles.listItem}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      width: { xs: "100%", md: "20%" },
                      alignItems: { xs: "center", md: "flex-start" },
                      mb: { xs: 2, md: 0 },
                    }}
                  >
                    {eventData.images && eventData.images.length > 0 ? (
                      <>
                        <Box style={styles.imageContainer}>
                          <CardMedia
                            component="img"
                            image={eventData.images[0]}
                            alt={`Imagen principal de ${eventData.name}`}
                            style={styles.mainImage}
                          />
                          {eventData.images.length > 1 && (
                            <Box
                              style={styles.additionalImagesOverlay}
                              onClick={() =>
                                handleOpenImageModal(eventData.images)
                              }
                            >
                              <Typography style={styles.additionalImagesCount}>
                                +{eventData.images.length - 1}
                              </Typography>
                              <Typography>Ver más</Typography>
                            </Box>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Box style={styles.noImage}>
                        <Typography>No hay imágenes disponibles</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "75%",
                      alignItems: { xs: "flex-start", md: "flex-start" },
                      mb: { xs: 2, md: 0 },
                    }}
                  >
                    <Typography style={styles.title}>
                      {eventData.title}
                    </Typography>
                    <Typography style={styles.description}>
                      {eventData.description}
                    </Typography>
                    <Typography style={styles.event}>
                      Tipo de evento: {eventData.eventType}
                    </Typography>
                    <Typography style={styles.date}>
                      Fecha: {formatDate(eventData.date)}
                    </Typography>
                    <Typography style={styles.created}>
                      Fecha de publicación: {formatDate(eventData.createdAt)}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography style={styles.description}>
                        Asistirán:
                      </Typography>
                      {attendees[eventData.id]
                        ?.slice(0, 3)
                        .map((attendeeId) => (
                          <Avatar
                            key={attendeeId}
                            src={
                              attendeeProfiles[attendeeId]?.profileImageUrl ||
                              ""
                            } // Mostrar la imagen si está disponible, de lo contrario, mostrar una cadena vacía
                            alt="Avatar"
                            sx={{
                              width: 24,
                              height: 24,
                              mr: "1px",
                              border: "1px solid #312783",
                              borderRadius: "50%",
                              mb: 2,
                              ml: 2,
                            }}
                          />
                        ))}

                      {attendees[eventData.id]?.length > 3 && (
                        <Typography variant="caption">
                          +{attendees[eventData.id].length - 3} más
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => handleAttend(eventData.id)} // Pasar el ID del evento a handleAttend
                    disabled={loading}
                  >
                    {attendees[eventData.id]?.includes(auth.currentUser?.uid)
                      ? "No asistiré"
                      : "Asistiré"}
                  </Button>
                </Box>
              </ListItem>
            ))}
          </List>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h6" component="div" sx={styles.name}>
            Empresas seguidas
          </Typography>
          <List sx={styles.list}>
            {followingDetails.map((company) => (
              <ListItem key={company.id} sx={styles.listi}>
                <Grid container alignItems="center" sx={{ padding: 1 }}>
                  <Grid item>
                    <CardMedia
                      component="img"
                      image={company.profileImageUrl}
                      title="Perfil"
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        marginRight: 16,
                        border: "2.5px solid #312783",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <div>
                      <Typography
                        sx={styles.namee}
                        variant="body1"
                        color="dodgerblue"
                      >
                        {company.displayName}
                      </Typography>
                      <Typography
                        sx={styles.email}
                        variant="body2"
                        color="grey"
                      >
                        📧 {company.email}
                      </Typography>
                      <Typography
                        sx={styles.email}
                        variant="body2"
                        color="grey"
                      >
                        📞 {company.phone}
                      </Typography>
                      <Typography
                        sx={styles.email}
                        variant="body2"
                        color="grey"
                      >
                        📍 {company.point}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </TabPanel>
      </Box>
      <Dialog
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        fullScreen // Make the modal full screen
      >
        <DialogTitle id="image-modal-title">
          Imágenes del Alojamiento
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="image-modal-description">
            {openImageModal && (
              <Box style={styles.imageModalContent}>
                {modalImages && modalImages.length > 0 ? (
                  <CardMedia
                    component="img"
                    image={modalImages[currentImageIndex]}
                    alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                    style={styles.imageModalImage}
                  />
                ) : (
                  <Typography>No hay imágenes disponibles</Typography>
                )}
                <Box style={styles.imageModalNavigation}>
                  <IconButton onClick={handlePreviousImage}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <IconButton onClick={handleNextImage}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <BottomToolbar />
    </Container>
  );
};

export default Miperfil;
