import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "react-calendar/dist/Calendar.css";
import {
  AccountCircle as AccountCircleIcon,
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  getDoc,
  doc,
  query,
  orderBy,
  serverTimestamp,
  deleteField,
  setDoc,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  

  


  // Suscripción a notificaciones
  useEffect(() => {
    const unsubscribe = subscribeToNotifications(setHasNewNotifications);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  // Escuchar nuevos posts de empresas seguidas
  useEffect(() => {
    const listenForNewPosts = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is currently authenticated");
        return;
      }

      try {
        // Consulta las empresas seguidas por el usuario
        const followedCompaniesQuery = query(
          collection(db, "followedCompanies"),
          where("userId", "==", user.uid)
        );

        const followedCompaniesSnapshot = await getDocs(followedCompaniesQuery);
        const followedCompanies = followedCompaniesSnapshot.docs.map(
          (doc) => doc.id
        );

        if (followedCompanies.length === 0) {
          console.log("User is not following any companies");
          return;
        }

        console.log("Followed companies: ", followedCompanies);

        // Escuchar nuevos posts
        const postsQuery = collection(db, "events");
        const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newPost = change.doc.data();
              console.log("New post: ", newPost);
              const postUserId = newPost.userId || newPost.uid || newPost.uide; // Asegurando el campo correcto
              if (!postUserId) {
                console.error("Post does not have a valid userId", newPost);
                return;
              }

              if (followedCompanies.includes(postUserId)) {
                // Consultar si ya existe una notificación para este post
                const notificationQuery = query(
                  collection(db, "notifications"),
                  where("userId", "==", user.uid),
                  where("postId", "==", change.doc.id)
                );

                getDocs(notificationQuery)
                  .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                      // Añadir nueva notificación si no existe
                      addDoc(collection(db, "notifications"), {
                        userId: user.uid,
                        companyId: postUserId,
                        postId: change.doc.id,
                        message: `${
                          newPost.displayName || newPost.name
                        } ha publicado un evento nuevo`,
                        read: false,
                        type: "event",
                        timestamp: serverTimestamp(),
                      })
                        .then(() => {
                          console.log("Notification added successfully");
                        })
                        .catch((error) => {
                          console.error("Error adding notification: ", error);
                        });
                    } else {
                      console.log("Notification already exists for this post");
                    }
                  })
                  .catch((error) => {
                    console.error("Error querying notifications: ", error);
                  });
              }
            }
          });
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error listening for new posts: ", error);
      }
    };

    listenForNewPosts();
  }, []);

  useEffect(() => {
    const fetchIcon = async () => {
      const result = await getIconForUserType();
      setIcon(result);
    };

    fetchIcon();
  });

  const getIconForUserType = async () => {
    const { uid } = auth.currentUser || {};
    if (!uid) {
      return null;
    }
    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const userType = userData.userType;

      if (userType === "empresa") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/publishEvents">
              <AddCircleIcon sx={{ color: "#312783" }} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Publicar
            </Typography>
          </div>
        );
      } else {
        return null;
      }
    }
  };

  const [icon, setIcon] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const eventsCollectionRef = collection(db, "events");
        const unsubscribe = onSnapshot(
          query(eventsCollectionRef, orderBy("createdAt", "desc")),
          (snapshot) => {
            const eventsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setLoading(false);
            setEvents(eventsData);
            console.log("Eventos obtenidas:", eventsData);
          }
        );
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener eventos:", error);
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const handleClickPerfil = (events) => {
    // Add offer as parameter
    if (events) {
      // Check if offer is available
      navigate(`/perfil/${events.uid}`);
    }
  };

  const [following, setFollowing] = useState([]);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const userFollowingRef = doc(db, "userFollowing", uid);
            const userFollowingDoc = await getDoc(userFollowingRef);
            
            if (userFollowingDoc.exists()) {
              const followingData = userFollowingDoc.data();
              const followingIds = Object.keys(followingData);
              setFollowing(followingIds);
            } else {
              setFollowing([]);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener las empresas seguidas:", error);
        setLoading(false);
      }
    };

    fetchFollowing();
  }, []);

  const handleFollow = async (companyId) => {
    try {
      const isAuthenticated = await isLoggedIn();
      if (isAuthenticated) {
        const { uid } = auth.currentUser || {};
        if (uid) {
          setLoading(true);
          const userFollowingRef = doc(db, "userFollowing", uid);
          const companyFollowersRef = doc(db, "companyFollowers", companyId);

          const isFollowing = following.includes(companyId);

          if (isFollowing) {
            // Dejar de seguir
            await updateDoc(userFollowingRef, {
              [companyId]: deleteField(),
            });
            await updateDoc(companyFollowersRef, {
              [uid]: deleteField(),
            });
            setFollowing(following.filter((id) => id !== companyId));
          } else {
            // Comenzar a seguir
            await setDoc(userFollowingRef, { [companyId]: true }, { merge: true });
            await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
            setFollowing([...following, companyId]);
          }
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar el seguimiento de la empresa:", error);
      setLoading(false);
    }
  };

  const isCompanyFollowed = (companyId) => following.includes(companyId);
  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };

  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = events.filter((event) =>
      event.name.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, events]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  const [attendees, setAttendees] = useState({});

  const handleAttend = async (eventId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const eventDocRef = doc(db, "events", eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          const eventData = eventDocSnap.data();
          const attendees = eventData.attendees || [];

          if (attendees.includes(user.uid)) {
            const updatedAttendees = attendees.filter(
              (attendeeId) => attendeeId !== user.uid
            );
            await updateDoc(eventDocRef, { attendees: updatedAttendees });
          } else {
            const updatedAttendees = [...attendees, user.uid];
            await updateDoc(eventDocRef, { attendees: updatedAttendees });
          }
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar la asistencia al evento:", error);
    }
  };
  const [attendeeProfiles, setAttendeeProfiles] = useState({});

  useEffect(() => {
    const fetchAttendees = async () => {
      const attendeesData = {};
      const profilesData = {}; // Objeto para almacenar las imágenes de perfil

      for (const event of events) {
        const eventDocRef = doc(db, "events", event.id);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          const eventData = eventDocSnap.data();
          attendeesData[event.id] = eventData.attendees || [];

          // Obtener las imágenes de perfil de los asistentes
          for (const attendeeId of attendeesData[event.id]) {
            if (!profilesData[attendeeId]) {
              // Evitar consultas duplicadas
              const userDocRef = doc(db, "users", attendeeId);
              const userDocSnap = await getDoc(userDocRef);

              if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                profilesData[attendeeId] = {
                  profileImageUrl: userData.profileImageUrl || "", // Obtener la URL de la imagen de perfil
                };
              }
            }
          }
        }
      }

      setAttendees(attendeesData);
      setAttendeeProfiles(profilesData); // Actualizar el estado con las imágenes de perfil
    };

    fetchAttendees();
  }, [events]);

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "99%",
      border: "0.5px solid #ccc",
      margin: "25px auto",
      boxShadow: "0px 0px 5px 0px black",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "15px",
      marginTop: "15px",
      textDecoration: "underline"
    },
    title: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "15px",
    },
    description: {
      fontWeight: "300",
      fontSize: "16px",
      marginBottom: "15px",
    },
    event: {
      color: "black",
      fontWeight: "700",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
      fontWeight: "400",
      marginBottom: "15px",
    },
    created: {
      fontWeight: "300",
      marginTop: "15px",
      marginBottom: "15px",
      fontSize: "12px",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },

    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
    imagePreview: {
      width: "100%",
      height: "200px",
      objectFit: "cover",
      borderRadius: "10px",
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      justifyContent: "space-around",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/home" color="black">
              <HomeIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Inicio
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/members" color="black">
              <SearchIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Buscar
            </Typography>
          </div>
          <div>{icon}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/notifications" color="black">
              <NotificationIcon hasNewNotifications={hasNewNotifications} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Notificaciones
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/profile" color="black">
              <AccountCircleIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Perfíl
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Notiline"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <AttachMoneyIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Ofertas
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/reference" color="black">
                <AccessTimeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Referencias
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/events" color="black">
                <EventIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Eventos
              </Typography>
            </div>
          </Toolbar>
          <Toolbar
            sx={{
              ...styles.search,
              padding: "0px 16px",
              borderRadius: "4px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <input
              type="text"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              style={{
                ...styles.inputS,
                width: "80%",
                padding: "8px 12px",
                borderRadius: "20px",
                border: "1px solid #e0e0e0",
                fontSize: "14px",
                transition: "border-color 0.3s",
                "&:focus": {
                  outline: "none",
                  borderColor: "#3f51b5",
                },
              }}
              placeholder="Buscar por negocio..."
            />
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {resultado.length > 0 ? (
                <List>
                  {resultado.map((event) => (
                    <ListItem key={event.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={event.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography
                            onClick={() => handleClickPerfil(event)}
                            style={styles.name}
                          >
                            {event.name}
                          </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "20%" },
                            alignItems: { xs: "center", md: "flex-start" },
                            mb: { xs: 2, md: 0 },
                          }}
                        >
                          {event.images && event.images.length > 0 ? (
                            <>
                              <Box style={styles.imageContainer}>
                                <CardMedia
                                  component="img"
                                  image={event.images[0]}
                                  alt={`Imagen principal de ${event.name}`}
                                  style={styles.mainImage}
                                />
                                {event.images.length > 1 && (
                                  <Box
                                    style={styles.additionalImagesOverlay}
                                    onClick={() =>
                                      handleOpenImageModal(event.images)
                                    }
                                  >
                                    <Typography
                                      style={styles.additionalImagesCount}
                                    >
                                      +{event.images.length - 1}
                                    </Typography>
                                    <Typography>Ver más</Typography>
                                  </Box>
                                )}
                              </Box>
                            </>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>
                                No hay imágenes disponibles
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "80%" },
                          }}
                        >
                          <Typography style={styles.title}>
                            {event.title}
                          </Typography>
                          <Typography style={styles.description}>
                            {event.description}
                          </Typography>
                          <Typography style={styles.event}>
                            Tipo de evento: {event.eventType}
                          </Typography>
                          <Typography style={styles.date}>
                            Fecha: {formatDate(event.date)}
                          </Typography>
                          <Typography style={styles.created}>
                            Fecha de publicación: {formatDate(event.createdAt)}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography style={styles.description}>
                              Asistirán:
                            </Typography>
                            {attendees[event.id]
                              ?.slice(0, 3)
                              .map((attendeeId) => (
                                <Avatar
                                  key={attendeeId}
                                  src={
                                    attendeeProfiles[attendeeId]
                                      ?.profileImageUrl || ""
                                  } // Mostrar la imagen si está disponible, de lo contrario, mostrar una cadena vacía
                                  alt="Avatar"
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    mr: "1px",
                                    border: "1px solid #312783",
                                    borderRadius: "50%",
                                    mb: 2,
                                    ml: 2,
                                  }}
                                />
                              ))}

                            {attendees[event.id]?.length > 3 && (
                              <Typography variant="caption">
                                +{attendees[event.id].length - 3} más
                              </Typography>
                            )}
                          </Box>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => handleAttend(event.id)} // Pasar el ID del evento a handleAttend
                            disabled={loading}
                          >
                            {attendees[event.id]?.includes(
                              auth.currentUser?.uid
                            )
                              ? "No asistiré"
                              : "Asistiré"}
                          </Button>
                          {/* Mostrar avatares de asistentes */}

                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => handleFollow(event.uid)}
                            disabled={loading}
                            sx={{ mt: 2 }} // Add margin-top: 20px
                          >
                            {isCompanyFollowed(event.uid)
                              ? "Dejar de seguir"
                              : "Seguir"}
                          </Button>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List>
                  {events.map((eventData) => (
                    <ListItem key={eventData.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={eventData.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography
                            onClick={() => handleClickPerfil(eventData)}
                            style={styles.name}
                          >
                            {eventData.name}
                          </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "20%" },
                            alignItems: { xs: "center", md: "flex-start" },
                            mb: { xs: 2, md: 0 },
                          }}
                        >
                          {eventData.images && eventData.images.length > 0 ? (
                            <>
                              <Box style={styles.imageContainer}>
                                <CardMedia
                                  component="img"
                                  image={eventData.images[0]}
                                  alt={`Imagen principal de ${eventData.name}`}
                                  style={styles.mainImage}
                                />
                                {eventData.images.length > 1 && (
                                  <Box
                                    style={styles.additionalImagesOverlay}
                                    onClick={() =>
                                      handleOpenImageModal(eventData.images)
                                    }
                                  >
                                    <Typography
                                      style={styles.additionalImagesCount}
                                    >
                                      +{eventData.images.length - 1}
                                    </Typography>
                                    <Typography>Ver más</Typography>
                                  </Box>
                                )}
                              </Box>
                            </>
                          ) : (
                            <Box style={styles.noImage}>
                              <Typography>
                                No hay imágenes disponibles
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "80%" },
                          }}
                        >
                          <Typography style={styles.title}>
                            {eventData.title}
                          </Typography>
                          <Typography style={styles.description}>
                            {eventData.description}
                          </Typography>
                          <Typography style={styles.event}>
                            Tipo de evento: {eventData.eventType}
                          </Typography>
                          <Typography style={styles.date}>
                            Fecha: {formatDate(eventData.date)}
                          </Typography>
                          <Typography style={styles.created}>
                            Fecha de publicación:{" "}
                            {formatDate(eventData.createdAt)}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography style={styles.description}>
                              Asistirán:
                            </Typography>
                            {attendees[eventData.id]
                              ?.slice(0, 3)
                              .map((attendeeId) => (
                                <Avatar
                                  key={attendeeId}
                                  src={
                                    attendeeProfiles[attendeeId]
                                      ?.profileImageUrl || ""
                                  } // Mostrar la imagen si está disponible, de lo contrario, mostrar una cadena vacía
                                  alt="Avatar"
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    mr: "1px",
                                    border: "1px solid #312783",
                                    borderRadius: "50%",
                                    mb: 2,
                                    ml: 2,
                                  }}
                                />
                              ))}

                            {attendees[eventData.id]?.length > 3 && (
                              <Typography variant="caption">
                                +{attendees[eventData.id].length - 3} más
                              </Typography>
                            )}
                          </Box>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => handleAttend(eventData.id)} // Pasar el ID del evento a handleAttend
                            disabled={loading}
                          >
                            {attendees[eventData.id]?.includes(
                              auth.currentUser?.uid
                            )
                              ? "No asistiré"
                              : "Asistiré"}
                          </Button>
                          {/* Mostrar avatares de asistentes */}

                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => handleFollow(eventData.uid)}
                            disabled={loading}
                            sx={{ mt: 2 }} // Add margin-top: 20px
                          >
                            {isCompanyFollowed(eventData.uid)
                              ? "Dejar de seguir"
                              : "Seguir"}
                          </Button>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </div>
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen // Make the modal full screen
        >
          <DialogTitle id="image-modal-title">
            Imágenes del Alojamiento
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {openImageModal && (
                <Box style={styles.imageModalContent}>
                  {modalImages && modalImages.length > 0 ? (
                    <CardMedia
                      component="img"
                      image={modalImages[currentImageIndex]}
                      alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                      style={styles.imageModalImage}
                    />
                  ) : (
                    <Typography>No hay imágenes disponibles</Typography>
                  )}
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Container>
  );
};

export default Events;
