import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Button,
  Grid,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  setDoc,
  updateDoc,
  deleteField,
  getDoc,
} from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import BottomToolbar from "./BottomToolbar";

const Members = () => {
  const [countrys, setCountrys] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [following, setFollowing] = useState([]);

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = countrys.filter((country) =>
      country.displayName.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, countrys]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  useEffect(() => {
    const fetchCountrys = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          setLoading(true);
          const countrysCollection = collection(db, "users");
          const q = query(
            countrysCollection,
            where("userType", "==", "empresa")
          );
          const countrysSnapshot = await getDocs(q);
          const countrysData = countrysSnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              key: doc.id,
              ...data,
              uid: data.uid,
              displayName: data.displayName,
              email: data.email,
              phone: data.phone,
              point: data.point,
            };
          });
          setCountrys(countrysData);
          setLoading(false);
          console.log("Empresas obtenidas:", countrysData);
        }
      } catch (error) {
        console.error("Error al obtener empresas:", error);
        setLoading(false);
      }
    };
    fetchCountrys();
  }, []);

  const handleClickPerfil = (country) => {
    if (country) {
      navigate(`/perfil/${country.uid}`);
    } else {
      console.error("User data not available yet");
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const userFollowingRef = doc(db, "userFollowing", uid);
            const userFollowingDoc = await getDoc(userFollowingRef);

            if (userFollowingDoc.exists()) {
              const followingData = userFollowingDoc.data();
              const followingIds = Object.keys(followingData);
              setFollowing(followingIds);
            } else {
              setFollowing([]);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener las empresas seguidas:", error);
        setLoading(false);
      }
    };

    fetchFollowing();
  }, []);

  const handleFollow = async (companyId) => {
    try {
      const isAuthenticated = await isLoggedIn();
      if (isAuthenticated) {
        const { uid } = auth.currentUser || {};
        if (uid) {
          setLoading(true);
          const userFollowingRef = doc(db, "userFollowing", uid);
          const companyFollowersRef = doc(db, "companyFollowers", companyId);

          const isFollowing = following.includes(companyId);

          if (isFollowing) {
            // Dejar de seguir
            await updateDoc(userFollowingRef, {
              [companyId]: deleteField(),
            });
            await updateDoc(companyFollowersRef, {
              [uid]: deleteField(),
            });
            setFollowing(following.filter((id) => id !== companyId));
          } else {
            // Comenzar a seguir
            await setDoc(
              userFollowingRef,
              { [companyId]: true },
              { merge: true }
            );
            await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
            setFollowing([...following, companyId]);
          }
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar el seguimiento de la empresa:", error);
      setLoading(false);
    }
  };

  const isCompanyFollowed = (companyId) => following.includes(companyId);

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "99.5%",
      border: "0.5px solid #ccc",
      margin: "10px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    typography: {
      marginBottom: "5px",
    },
    textField: {
      marginBottom: "15px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      width: "100%",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
  };

  return (
    <Container>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Notiline"
            style={styles.media}
          />
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <AttachMoneyIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Ofertas
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/reference" color="black">
                <AccessTimeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Referencias
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/events" color="black">
                <EventIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Eventos
              </Typography>
            </div>
          </Toolbar>
          <Toolbar
            sx={{
              ...styles.search,
              padding: "0px 16px",
              borderRadius: "4px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <input
              type="text"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              style={{
                ...styles.inputS,
                width: "80%",
                padding: "8px 12px",
                borderRadius: "20px",
                border: "1px solid #e0e0e0",
                fontSize: "14px",
                transition: "border-color 0.3s",
                "&:focus": {
                  outline: "none",
                  borderColor: "#3f51b5",
                },
              }}
              placeholder="Buscar por negocio..."
            />
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <List style={styles.list}>
              {(resultado.length > 0 ? resultado : countrys).map((country) => (
                <ListItem style={styles.listItem} key={country.key}>
                  <Grid container alignItems="center" sx={{ padding: 1 }}>
                    <Grid item>
                      <CardMedia
                        component="img"
                        image={country.profileImageUrl}
                        title="Perfil"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          marginRight: 16,
                          border: "2.5px solid #312783",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <div>
                        <Typography variant="body1" color="dodgerblue">
                          <Typography
                            onClick={() => handleClickPerfil(country)} // Pass the country object here
                            style={styles.name}
                          >
                            {country.displayName}
                          </Typography>
                        </Typography>
                        <Typography variant="body2" color="grey">
                          📧 {country.email}
                        </Typography>
                        <Typography variant="body2" color="grey">
                          📞 {country.phone}
                        </Typography>
                        <Typography variant="body2" color="grey">
                          📍 {country.point}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Button
                    onClick={() => handleFollow(country.key)}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2, marginBottom: 1 }}
                  >
                    {isCompanyFollowed(country.key)
                      ? "Dejar de seguir"
                      : "Seguir"}
                  </Button>
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Container>
      <BottomToolbar />
    </Container>
  );
};

export default Members;
