import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getToken } from "./firebase";

const auth = getAuth();

let currentUser = null; // Variable global para almacenar el usuario actual

// Función para obtener la sesión actual
export const getSession = () => {
  return new Promise((resolve, reject) => {
    if (currentUser) {
      // Si ya hay un usuario autenticado, resolver con ese usuario
      resolve(currentUser);
    } else {
      // Si no hay un usuario autenticado, suscribirse a los cambios de autenticación
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe(); // Cancelar la suscripción después de obtener el usuario una vez

        if (user) {
          getToken(user)
            .then((idToken) => {
              // Almacenar el usuario actual
              currentUser = {
                email: user.email,
                uid: user.uid,
                idToken: idToken
              };
              resolve(currentUser);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          // Si no hay un usuario autenticado, resolver con null
          resolve(null);
        }
      }, (error) => {
        reject(error);
      });
    }
  });
};

// Función para iniciar sesión
export const startSession = () => {
  // Lógica para iniciar sesión
};

// Función para cerrar sesión
export const endSession = async () => {
  try {
    // Cerrar sesión del usuario actual
    await signOut(auth);
    // Limpiar el usuario actual
    currentUser = null;
    // Si el cierre de sesión es exitoso, devolver true
    return true;
  } catch (error) {
    // Si hay algún error, lanzar una excepción con el mensaje de error
    throw new Error(error.message);
  }
};

// Función para verificar si el usuario está autenticado
export const isLoggedIn = async () => {
  const user = await getSession();
  return !!user;
};
