import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Grid,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { auth, db, uploadImage } from "../firebase";
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import Atras from "./atras";

const PublishOffer = () => {
  const [formData, setFormData] = useState({
    product: "",
    description: "",
    offer: "",
    url: "",
    cupon: "",
    images: [],
  });
  const [loading, setLoading] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Clear the error for the field when it's modified
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate each field
    Object.keys(formData).forEach((key) => {
      if (key !== "images" && formData[key].trim() === "") {
        newErrors[key] = "Este campo es obligatorio";
        isValid = false;
      }
    });

    // Validate images
    if (formData.images.length === 0) {
      newErrors.images = "Debes subir al menos una imagen";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handlePublishOffer = async () => {
    if (!validateForm()) {
      return;
    }

    const user = auth.currentUser;

    if (!user) {
      console.error("No user is signed in");
      return;
    }

    setLoading(true);

    try {
      const { uid } = user;
      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const {
          displayName: userName,
          profileImageUrl: imageProfile,
          uid: userId,
        } = userData;

        await addDoc(collection(db, "offers"), {
          uid: userId,
          name: userName,
          image: imageProfile,
          product: formData.product,
          offer: formData.offer,
          url: formData.url,
          cupon: formData.cupon,
          description: formData.description,
          images: formData.images,
          createdAt: serverTimestamp(),
        });

        setPublishSuccess(true);
      } else {
        console.error("User document does not exist");
      }
    } catch (error) {
      console.error("Error publishing offer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files; // Get all selected files

    if (!files) {
      return;
    }

    setLoading(true);

    try {
      const imageUrls = []; // Array to store URLs of all uploaded images

      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls], // Add all new URLs to the array
      }));
    } catch (error) {
      console.error("Error uploading images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: prevFormData.images.filter((_, i) => i !== index),
    }));
  };

  const handleRedirectHome = () => {
    history("/home");
  };

  const styles = {
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    removeButton: {
      display: "block",
      color: "white",
      cursor: "pointer",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Oferta
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 14, mb: 10 }}>
        <TextField
          autoFocus
          margin="dense"
          id="product"
          label="Nombre del producto"
          type="text"
          fullWidth
          required
          name="product"
          value={formData.product}
          onChange={handleInputChange}
          error={!!errors.product}
          helperText={errors.product}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descripción del producto"
          type="text"
          fullWidth
          required
          name="description"
          rows={3}
          multiline
          value={formData.description}
          onChange={handleInputChange}
          error={!!errors.description}
          helperText={errors.description}
        />
        <TextField
          margin="dense"
          id="offer"
          label="Descuento %"
          type="text"
          fullWidth
          required
          name="offer"
          value={formData.offer}
          onChange={handleInputChange}
          error={!!errors.offer}
          helperText={errors.offer}
        />
        <TextField
          margin="dense"
          id="url"
          label="Ejemplo.com/producto"
          type="text"
          fullWidth
          required
          name="url"
          value={formData.url}
          onChange={handleInputChange}
          error={!!errors.url}
          helperText={errors.url}
        />
        <TextField
          margin="dense"
          id="cupon"
          label="Nombre del cupón de descuento"
          type="text"
          fullWidth
          required
          name="cupon"
          value={formData.cupon}
          onChange={handleInputChange}
          error={!!errors.cupon}
          helperText={errors.cupon}
        />
        <label htmlFor="upload-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            component="div"
            sx={styles.uploadButton}
            disabled={loading}
            required
          >
            <AddPhotoAlternateIcon sx={{ color: "black" }} />
            Subir Imagenes del producto
            {loading && (
              <CircularProgress size={40} thickness={4} sx={styles.progress} />
            )}
          </Button>
        </label>
        <input
          type="file"
          accept="image/*"
          onClick={(event) => (event.target.value = null)}
          onChange={handleImageUpload}
          style={{ display: "none" }}
          id="upload-button"
          multiple
        />
        {errors.images && (
          <Typography color="error" variant="caption">
            {errors.images}
          </Typography>
        )}
        <Grid container sx={{ mt: 2 }}>
          {formData.images.map((imageUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  border: "1.5px solid black",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Imagen de la propiedad"
                  style={styles.imagePreview}
                />
                <Button
                  variant="contained"
                  sx={styles.removeButton}
                  onClick={() => handleRemoveImage(index)}
                >
                  ELIMINAR
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
        {publishSuccess && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>La oferta se ha publicado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Ofertas
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handlePublishOffer}
            color="primary"
            disabled={loading}
          >
            {loading ? "Publicando..." : "Publicar"}
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default PublishOffer;
