import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  AppBar,
  Toolbar,
  ListItem,
  CardMedia,
  CircularProgress,
  Button
} from "@mui/material";
import Atras from "./atras";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import { collection, getDocs, where, query, orderBy, doc, onSnapshot } from "firebase/firestore";
import CopyToClipboardComponent from "./copy";
import BottomToolbar from "./BottomToolbar";

const Notifications = () => {
  const [combinedData, setCombinedData] = useState([]);
  const [following, setFollowing] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("all"); // Default: show all items
  const [newNotifications, setNewNotifications] = useState(false);

  useEffect(() => {
    const checkNewNotifications = async () => {
      try {
        const { uid } = auth.currentUser;
        const userDocRef = doc(db, "users", uid);
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setNewNotifications(userData.newNotifications || false);
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error checking for new notifications:", error);
      }
    };

    checkNewNotifications();
  }, []);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          setLoading(true);
          const { uid } = auth.currentUser || {};
          if (uid) {
            const followedCompaniesCollection = collection(
              db,
              "followedCompanies"
            );
            const followedCompaniesQuery = query(
              followedCompaniesCollection,
              where("userId", "==", uid)
            );
            const followedCompaniesSnapshot = await getDocs(
              followedCompaniesQuery
            );
            const followedCompanyIds = followedCompaniesSnapshot.docs.map(
              (doc) => doc.id
            );
            setFollowing(followedCompanyIds);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error al obtener las empresas seguidas:", error);
        setLoading(false);
      }
    };
    fetchFollowing();
  }, []);

  useEffect(() => {
    const getOffersAndEventsByFollowedIds = async () => {
      try {
        if (following.length > 0) {
          const offersCollection = collection(db, "offers");
          const eventsCollection = collection(db, "events");

          // Consulta de ofertas ordenadas por fecha de creación descendente en Firestore
          const offersQuery = query(
            offersCollection,
            where("uid", "in", following),
            orderBy("createdAt", "desc")
          );

          // Consulta de eventos ordenados por fecha de creación descendente en Firestore
          const eventsQuery = query(
            eventsCollection,
            where("uid", "in", following),
            orderBy("createdAt", "desc")
          );

          // Obtener los snapshots de las consultas
          const [offersSnapshot, eventsSnapshot] = await Promise.all([
            getDocs(offersQuery),
            getDocs(eventsQuery),
          ]);

          // Mapear los datos de ofertas
          const offersData = offersSnapshot.docs.map((doc) => ({
            id: doc.id,
            type: "offer",
            ...doc.data(),
          }));

          // Mapear los datos de eventos
          const eventsData = eventsSnapshot.docs.map((doc) => ({
            id: doc.id,
            type: "event",
            ...doc.data(),
          }));

          // Combinar los datos de ofertas y eventos
          const combinedData = [...offersData, ...eventsData];

          // Filtrar por nombre si hay un término de búsqueda
          const filteredData = combinedData.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          // Filtrar por tipo de propiedad si se ha seleccionado
          const filteredAndTypedData =
            filterType === "all"
              ? filteredData
              : filteredData.filter((item) => item.type === filterType);

          // Ordenar los datos combinados por fecha de creación en el lado del cliente
          filteredAndTypedData.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          // Establecer el estado con los datos combinados, filtrados y ordenados
          setCombinedData(filteredAndTypedData);
        } else {
          setCombinedData([]);
        }
      } catch (error) {
        console.error(
          "Error al obtener ofertas y eventos de empresas seguidas:",
          error
        );
      }
    };

    getOffersAndEventsByFollowedIds();
  }, [following, searchTerm, filterType]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };

  const getDomainFromUrl = (url) => {
    // Si la URL no es válida, devuelve la URL original
    if (!url) {
      return url;
    }

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    const domain = new URL(url).hostname;

    // Encuentra el punto "." antes de la extensión
    const dotIndex = domain.lastIndexOf(".");

    // Si no se encuentra un punto, devuelve el dominio completo
    if (dotIndex === -1) {
      return domain;
    }

    // Extrae el dominio hasta el punto "."
    const shortenedDomain = domain.substring(0, dotIndex);

    // Si el dominio es demasiado largo, lo recorta y agrega "..."
    if (shortenedDomain.length > 15) {
      return shortenedDomain.slice(0, 15) + "...";
    }

    // Devuelve el dominio recortado
    return shortenedDomain;
  };

  const renderListItem = (combinedData) => {
    if (combinedData.type === "offer") {
      return (
        <ListItem key={combinedData.id} style={styles.listItem}>
          <Box style={styles.contUser}>
            <CardMedia
              component="img"
              image={combinedData.image}
              title="Perfil"
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                marginRight: 16,
                border: "2.5px solid #312783",
              }}
            />
            <Typography
              variant="h5"
              sx={{ color: "#312783" }}
              style={styles.name}
            >
              {combinedData.name}
            </Typography>
          </Box>
          <Typography variant="subtitle1" style={styles.typography}>
            {combinedData.product}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={styles.typography}
          >
            {combinedData.description}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={`http://${combinedData.url}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ width: "100%", mt: 1, mb: 1 }}
          >
            Comprar en {getDomainFromUrl(combinedData.url)}
          </Button>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            sx={{ color: "dodgerblue" }}
            style={styles.offer}
          >
            Descuento: {combinedData.offer} %
          </Typography>
          <CopyToClipboardComponent textToCopy={combinedData.cupon} />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: "10px" }}
            style={styles.created}
          >
            Fecha de publicación: {formatDate(combinedData.createdAt)}
          </Typography>
        </ListItem>
      );
    } else if (combinedData.type === "event") {
      return (
        <ListItem key={combinedData.id} style={styles.listItem}>
          <Box style={styles.contUser}>
            <CardMedia
              component="img"
              image={combinedData.image}
              title="Perfil"
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                marginRight: 16,
                border: "2.5px solid #312783",
              }}
            />
            <Typography
              variant="h5"
              sx={{ color: "#312783" }}
              style={styles.name}
            >
              {combinedData.name}
            </Typography>
          </Box>
          <Typography variant="subtitle1" style={styles.titleE}>
            {combinedData.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={styles.typography}
          >
            {combinedData.description}
          </Typography>
          <Typography style={styles.date}>
            Fecha: {formatDate(combinedData.date)}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: "10px" }}
            style={styles.created}
          >
            Fecha de publicación: {formatDate(combinedData.createdAt)}
          </Typography>
        </ListItem>
      );
    }
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    contFilt: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: "140px",
    },
    inputS: {
      display: "block",
      width: "60vw",
      height: "30px",
      marginLeft: "-10px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1spx solid grey",
      borderRadius: "30px",
    },
    drop: {
      display: "block",
      width: "auto",
      height: "42px",
      marginLeft: "7px",
      marginRight: "13px",
      padding: "5px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    titleE: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      marginBottom: "15px",
      color: "lightseagreen",
    },
    typography: {
      marginBottom: "15px",
    },
    offer: {
      color: "dodgerblue",
      fontSize: "18px",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
    },
    created: {
      fontSize: "10px",
      marginBottom: "15px",
      marginTop: "15px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
          <Typography variant="h5" color={"white"} gutterBottom>
              Notificaciones {newNotifications && " (Nuevas)"}
            </Typography>
          </Container>
        </Toolbar>
        <Toolbar sx={styles.search}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            style={styles.inputS}
            placeholder="Buscar por nombre"
          />
          <select
            value={filterType}
            onChange={handleFilterChange}
            style={styles.drop}
          >
            <option value="all">Todos</option>
            <option value="offer">Ofertas</option>
            <option value="event">Eventos</option>
          </select>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>{combinedData.map((data) => renderListItem(data))}</>
          )}
        </div>
      </Container>
      <BottomToolbar />
    </Container>
  );
};

export default Notifications;
