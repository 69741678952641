import React from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  Box,
  Button,
} from "@mui/material";
import Atras from "./atras";
import BottomToolbar from "./BottomToolbar";
import SkachLogo from "../imgs/Skach.png";
import ChatioLogo from "../imgs/Chatio.png";
import TripdioLogo from "../imgs/Tripdio.png";
import FindMeLogo from "../imgs/Findme.png";
import CarioLogo from "../imgs/Cario.png";


const Apps = () => {
  const styles = {
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
  };

  const handleGoToSkach = () => {
    window.location.href = "https://skach.com.ar/";
  };

  const handleGoToChatio = () => {
    window.location.href = "https://chatio-9ce76.web.app/";
  };

  const handleGoToTripdio = () => {
    window.location.href = "https://tripdio.web.app/";
  };

  const handleGoToFindMe = () => {
    window.location.href = "https://findme-e42aa.web.app/";
  };


  const handleGoToCario = () => {
    window.location.href = "https://cario-58145.web.app/";
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />

          <Container style={styles.user}>
            <Typography variant="h5" color={"white"} gutterBottom>
              Apps de Notiline
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 16, mb: 16 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "100%",
              padding: "10px",
              borderRadius: "10px",
              margin: "20px auto",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={SkachLogo}
                alt="Skach"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <Typography>Skach ___ ¡Crea un proyecto o busca uno!</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={handleGoToSkach}
              >
                Ir a Skach
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              padding: "10px",
              borderRadius: "10px",
              margin: "20px auto",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={ChatioLogo}
                alt="Chatio"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <Typography>
                Chatio - ¡Publica, chatea y compra productos usados!
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={handleGoToChatio}
              >
                Ir a Chatio
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              padding: "10px",
              borderRadius: "10px",
              margin: "20px auto",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={TripdioLogo}
                alt="Tripdio"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <Typography>
                Tripdio - ¡Publica y/o alquila una propiedad para viajar!
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={handleGoToTripdio}
              >
                Ir a Tripdio
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              padding: "10px",
              borderRadius: "10px",
              margin: "20px auto",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={FindMeLogo}
                alt="FindMe"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <Typography>
                FindMe - ¡Encuentrate con la persona que necesites trabajar!
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={handleGoToFindMe}
              >
                Ir a FindMe
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              padding: "10px",
              borderRadius: "10px",
              margin: "20px auto",
              backgroundColor: "white",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={CarioLogo}
                alt="FindMe"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <Typography>
                Cario - ¡Vende y compra vehículos al mejor precio!
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1, mb: 1 }}
                onClick={handleGoToCario}
              >
                Ir a Cario
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <BottomToolbar />
    </Container>
  );
};

export default Apps;
