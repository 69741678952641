import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "react-calendar/dist/Calendar.css";
import {
  AccountCircle as AccountCircleIcon,
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  getDoc,
  doc,
  query,
  serverTimestamp,
  deleteDoc,
  setDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [error, setError] = useState(null); // State for error messages
  const [following, setFollowing] = useState([]); // State for followed companies
  const [reference, setReference] = useState([]); // State for reference data
  const [cardImages, setCardImages] = useState({
    visa: null,
    mastercard: null,
    amex: null,
    diners: null,
    maestro: null,
    naranja: null,
    cabal: null,
    mercadopago: null,
  });

  useEffect(() => {
    const fetchCardImages = async () => {
      const imageUrls = {
        visa: null,
        mastercard: null,
        amex: null,
        diners: null,
        maestro: null,
        naranja: null,
        cabal: null,
        mercadopago: null,
      };

      for (const card in imageUrls) {
        const storageRef = ref(storage, `cards/${card}.png`);
        try {
          const url = await getDownloadURL(storageRef);
          imageUrls[card] = url;
        } catch (error) {
          console.error(`Error getting image URL for ${card}:`, error);
          // Consider setting a default image URL here if there's an error
          imageUrls[card] = "/path/to/default.png";
        }
      }
      setCardImages(imageUrls);
    };

    fetchCardImages();
  }, []);

  // Suscripción a notificaciones
  useEffect(() => {
    const unsubscribe = subscribeToNotifications(setHasNewNotifications);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  // Escuchar nuevos posts de empresas seguidas
  useEffect(() => {
    const listenForNewPosts = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is currently authenticated");
        return;
      }

      try {
        // Consulta las empresas seguidas por el usuario
        const followedCompaniesQuery = query(
          collection(db, "followedCompanies"),
          where("userId", "==", user.uid)
        );

        const followedCompaniesSnapshot = await getDocs(followedCompaniesQuery);
        const followedCompanies = followedCompaniesSnapshot.docs.map(
          (doc) => doc.id
        );

        if (followedCompanies.length === 0) {
          console.log("User is not following any companies");
          return;
        }

        console.log("Followed companies: ", followedCompanies);

        // Escuchar nuevos posts
        const postsQuery = query(
          collection(db, "reference"),
          where("uid", "in", followedCompanies) // Filter by followed companies
        );
        const unsubscribe = onSnapshot(postsQuery, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newPost = change.doc.data();
              console.log("New post: ", newPost);
              const postUserId = newPost.userId || newPost.uid || newPost.uide; // Asegurando el campo correcto
              if (!postUserId) {
                console.error("Post does not have a valid userId", newPost);
                return;
              }

              if (followedCompanies.includes(postUserId)) {
                // Consultar si ya existe una notificación para este post
                const notificationQuery = query(
                  collection(db, "notifications"),
                  where("userId", "==", user.uid),
                  where("postId", "==", change.doc.id)
                );

                getDocs(notificationQuery)
                  .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                      // Añadir nueva notificación si no existe
                      addDoc(collection(db, "notifications"), {
                        userId: user.uid,
                        companyId: postUserId,
                        postId: change.doc.id,
                        message: `${
                          newPost.displayName || newPost.name
                        } ha publicado una nueva oferta`,
                        read: false,
                        timestamp: serverTimestamp(),
                      })
                        .then(() => {
                          console.log("Notification added successfully");
                        })
                        .catch((error) => {
                          console.error("Error adding notification: ", error);
                          setError(
                            "Error adding notification. Please try again later."
                          );
                        });
                    } else {
                      console.log("Notification already exists for this post");
                    }
                  })
                  .catch((error) => {
                    console.error("Error querying notifications: ", error);
                    setError(
                      "Error querying notifications. Please try again later."
                    );
                  });
              }
            }
          });
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error listening for new posts: ", error);
        setError("Error listening for new posts. Please try again later.");
      }
    };

    listenForNewPosts();
  }, []);

  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const fetchIcon = async () => {
      const result = await getIconForUserType();
      setIcon(result);
    };

    fetchIcon();
  }, []);

  const getIconForUserType = async () => {
    const { uid } = auth.currentUser || {};
    if (!uid) {
      return null;
    }

    try {
      // Verificar el tipo de usuario
      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const userType = userData.userType;

        if (userType === "empresa") {
          // Verificar si el usuario ya tiene una publicación en la colección "reference"
          const referenceQuery = query(
            collection(db, "reference"),
            where("uid", "==", uid)
          );
          const referenceSnapshot = await getDocs(referenceQuery);

          if (referenceSnapshot.empty) {
            // El usuario no tiene publicaciones, mostrar el icono
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton href="/publishReference">
                  <AddCircleIcon sx={{ color: "#312783" }} />
                </IconButton>
                <Typography variant="caption" fontSize={9} align="center">
                  Publicar
                </Typography>
              </div>
            );
          }
        }
      }
    } catch (error) {
      console.error("Error al verificar el estado de publicación:", error);
      setError(
        "Error al verificar el estado de publicación. Por favor, inténtelo de nuevo más tarde."
      );
    }

    return null;
  };

  // Fetch reference data for followed companies
  useEffect(() => {
    const fetchReference = async () => {
      try {
        setLoading(true);
        const user = auth.currentUser;
        if (!user) {
          console.error("No user is currently authenticated");
          return;
        }

        // Consulta las empresas seguidas por el usuario
        const followedCompaniesQuery = query(
          collection(db, "followedCompanies"),
          where("userId", "==", user.uid)
        );

        const followedCompaniesSnapshot = await getDocs(followedCompaniesQuery);
        const followedCompanies = followedCompaniesSnapshot.docs.map(
          (doc) => doc.id
        );

        if (followedCompanies.length === 0) {
          console.log("User is not following any companies");
          return;
        }

        // Fetch reference data for followed companies
        const referenceQuery = query(
          collection(db, "reference"),
          where("uid", "in", followedCompanies)
        );
        const unsubscribe = onSnapshot(referenceQuery, (snapshot) => {
          const referenceData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLoading(false);
          setReference(referenceData);
          console.log("Referencias obtenidas:", referenceData);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener referencias:", error);
        setLoading(false);
        setError("Error fetching references. Please try again later.");
      }
    };
    fetchReference();
  }, [following]);

  const handleClickPerfil = (reference) => {
    // Add offer as parameter
    if (reference) {
      // Check if offer is available
      navigate(`/perfil/${reference.uid}`);
    }
  };

  // Fetch followed companies
  useEffect(() => {
    // Recuperar la lista de empresas seguidas de Firestore
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const followedCompaniesCollection = collection(
              db,
              "followedCompanies"
            );
            const followedCompaniesQuery = query(
              followedCompaniesCollection,
              where("userId", "==", uid)
            );
            const followedCompaniesSnapshot = await getDocs(
              followedCompaniesQuery
            );
            const followedCompanyIds = followedCompaniesSnapshot.docs.map(
              (doc) => doc.id
            );
            setFollowing(followedCompanyIds);
          }
        }
      } catch (error) {
        console.error("Error al obtener las empresas seguidas:", error);
        setError("Error fetching followed companies. Please try again later.");
      }
    };
    fetchFollowing();
  }, []);

  const handleFollow = async (id) => {
    try {
      const isAuthenticated = await isLoggedIn();
      if (isAuthenticated) {
        const { uid } = auth.currentUser || {};
        if (uid) {
          let updatedFollowing;
          if (following.includes(id)) {
            // Si la empresa ya está siendo seguida, eliminarla de Firestore
            await deleteDoc(doc(db, "followedCompanies", id)); // Here's the issue
            updatedFollowing = following.filter(
              (companyId) => companyId !== id
            );
          } else {
            // Si la empresa no está siendo seguida, agregarla a Firestore
            await setDoc(doc(db, "followedCompanies", id), { userId: uid }); // Here's the issue
            updatedFollowing = [...following, id];
          }
          // Actualizar la lista de empresas seguidas en el estado local
          setFollowing(updatedFollowing);
        }
      } else {
        // Usuario no autenticado, redirigir al inicio de sesión
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar el seguimiento de la empresa:", error);
      setError("Error following company. Please try again later.");
    }
  };

  const isCompanyFollowed = (id) => {
    return following.includes(id);
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };
  const orderDays = (hours) => {
    if (!hours || typeof hours !== "object") {
      return [];
    }

    const daysOfWeek = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ];

    const dayMappings = {
      lunes: "Lunes",
      martes: "Martes",
      miercoles: "Miércoles",
      miércoles: "Miércoles",
      jueves: "Jueves",
      viernes: "Viernes",
      sabado: "Sábado",
      sábado: "Sábado",
      domingo: "Domingo",
      mon: "Lunes",
      tue: "Martes",
      wed: "Miércoles",
      thu: "Jueves",
      fri: "Viernes",
      sat: "Sábado",
      sun: "Domingo",
    };

    const orderedDays = daysOfWeek
      .map((day) => {
        const key = Object.keys(hours).find(
          (k) => dayMappings[k.toLowerCase()] === day || k === day
        );
        return key ? [day, hours[key]] : null;
      })
      .filter(Boolean);

    return orderedDays;
  };

  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = reference.filter((reference) =>
      reference.name.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, reference]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "99%",
      border: "0.5px solid #ccc",
      margin: "25px auto",
      boxShadow: "0px 0px 5px 0px black",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "15px",
      marginTop: "15px",
      textDecoration: "underline",
    },
    title: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "15px",
    },
    description: {
      fontWeight: "400",
      fontSize: "16px",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
      fontWeight: "400",
      marginBottom: "0px",
    },
    cards: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "0px",
    },
    created: {
      fontWeight: "300",
      marginTop: "15px",
      marginBottom: "15px",
      fontSize: "12px",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton href="/home" color="black">
              <HomeIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Inicio
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton href="/members" color="black">
              <SearchIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Buscar
            </Typography>
          </div>
          <div>{icon}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton href="/notifications" color="black">
              <NotificationIcon hasNewNotifications={hasNewNotifications} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Notificaciones
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton href="/profile" color="black">
              <AccountCircleIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Perfíl
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Notiline"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <AttachMoneyIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Ofertas
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/reference" color="black">
                <AccessTimeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Referencias
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/events" color="black">
                <EventIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Eventos
              </Typography>
            </div>
          </Toolbar>
          <Toolbar
            sx={{
              ...styles.search,
              padding: "0px 16px",
              borderRadius: "4px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <input
              type="text"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              style={{
                ...styles.inputS,
                width: "80%",
                padding: "8px 12px",
                borderRadius: "20px",
                border: "1px solid #e0e0e0",
                fontSize: "14px",
                transition: "border-color 0.3s",
                "&:focus": {
                  outline: "none",
                  borderColor: "#3f51b5",
                },
              }}
              placeholder="Buscar por negocio..."
            />
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        <Container>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
        </Container>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {resultado.length > 0 ? (
                <List>
                  {resultado.map((reference) => (
                    <ListItem key={reference.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={reference.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography
                          onClick={() => handleClickPerfil(reference)}
                          style={styles.name}
                        >
                          {reference.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Typography style={styles.title}>
                            {reference.title}
                          </Typography>
                          <Typography style={styles.description}>
                            Descripción: {reference.description}
                          </Typography>
                          <Typography
                            style={{
                              color: "black",
                              fontWeight: "400",
                              marginBottom: "15px",
                            }}
                          >
                            Horarios:
                          </Typography>
                          {reference.hours && (
                            <List>
                              {orderDays(reference.hours).map(
                                ([day, hours]) => (
                                  <ListItem key={day}>
                                    <Typography style={styles.date}>
                                      {day}: {hours}
                                    </Typography>
                                  </ListItem>
                                )
                              )}
                            </List>
                          )}
                        </Box>
                        <List>
                          <Typography style={styles.cards}>
                            <Typography color={"black"} sx={{ mb: 2 }}>
                              Tarjetas aceptadas:
                            </Typography>{" "}
                            {reference.tarjetas?.length > 0 ? (
                              <Box display="flex" flexWrap="wrap">
                                {reference.tarjetas.map((card, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: 1,
                                      marginBottom: 1,
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      image={
                                        cardImages[card.toLowerCase()] ||
                                        "/path/to/default.png"
                                      }
                                      title={card}
                                      sx={{ width: 40, height: 40 }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            ) : (
                              <Typography color="gray">
                                No hay tarjetas disponibles
                              </Typography>
                            )}
                          </Typography>
                        </List>

                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={() => handleFollow(reference.uid)}
                          disabled={loading}
                        >
                          {isCompanyFollowed(reference.uid)
                            ? "Dejar de seguir"
                            : "Seguir"}
                        </Button>
                      </Box>
                      <Typography style={styles.created}>
                        Fecha de publicación: {formatDate(reference.createdAt)}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List>
                  {reference.map((referenceData) => (
                    <ListItem key={referenceData.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={referenceData.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography
                          onClick={() => handleClickPerfil(referenceData)}
                          style={styles.name}
                        >
                          {referenceData.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: { xs: "column", md: "row" },
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "20%" },
                            alignItems: { xs: "center", md: "flex-start" },
                            mb: { xs: 2, md: 0 },
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={referenceData.imageUrl}
                            title="Perfil"
                            style={{
                              width: 150,
                              height: "auto",
                              borderRadius: "10%",
                              marginRight: 16,
                              border: "2.5px solid #312783",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "column",
                            width: { xs: "100%", md: "80%" },
                          }}
                        >
                          <Typography style={styles.title}>
                            {referenceData.title}
                          </Typography>
                          <Typography style={styles.description}>
                            {referenceData.description}
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "column",
                              width: { xs: "100%", md: "80%" },
                            }}
                          >
                            <Typography style={styles.cards}>
                              <Typography color={"black"} sx={{ mb: 2 }}>
                                Tarjetas aceptadas:
                              </Typography>{" "}
                              {reference.tarjetas?.length > 0 ? (
                                <Box display="flex" flexWrap="wrap">
                                  {reference.tarjetas.map((card, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: 1,
                                        marginBottom: 1,
                                      }}
                                    >
                                      <CardMedia
                                        component="img"
                                        image={
                                          cardImages[card.toLowerCase()] ||
                                          "/path/to/default.png"
                                        }
                                        title={card}
                                        sx={{ width: 40, height: 40 }}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                              ) : (
                                <Typography color="gray">
                                  No hay tarjetas disponibles
                                </Typography>
                              )}
                            </Typography>
                          </Box>

                          <Typography style={styles.date}>
                            Tarjetas: {referenceData.cards}
                          </Typography>
                          <Typography style={styles.date}>
                            Fecha: {formatDate(referenceData.date)}
                          </Typography>

                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => handleFollow(referenceData.uid)}
                            disabled={loading}
                          >
                            {isCompanyFollowed(referenceData.uid)
                              ? "Dejar de seguir"
                              : "Seguir"}
                          </Button>
                          <Typography style={styles.created}>
                            Fecha de publicación:{" "}
                            {formatDate(referenceData.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default Events;
