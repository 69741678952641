import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  CircularProgress,
  Box,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db, getToken } from "../firebase";
import Atras from "./atras";
import {
  collection,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  AttachMoney as AttachMoneyIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import BottomToolbar from "./BottomToolbar";
import { getDownloadURL, ref } from "firebase/storage"; // Import storage functions
import { storage } from "../firebase";

const EditReference = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [uid, setUid] = useState(null);
  const [selectedReferenceId, setSelectedReferenceId] = useState(null);

  const [description, setDescription] = useState("");
  const [hours, setHours] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  });
  const [cardImages, setCardImages] = useState({
    visa: null,
    mastercard: null,
    amex: null,
    diners: null,
    maestro: null,
    naranja: null,
    cabal: null,
    mercadopago: null,
  });

  const [references, setReferences] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);
  const [tarjetas, setTarjetas] = useState({}); // Use an object to store card states

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchCardImages = async () => {
      const imageUrls = {
        visa: null,
        mastercard: null,
        amex: null,
        diners: null,
        maestro: null,
        naranja: null,
        cabal: null,
        mercadopago: null,
      };

      for (const card in imageUrls) {
        const storageRef = ref(storage, `cards/${card}.png`);
        try {
          const url = await getDownloadURL(storageRef);
          imageUrls[card] = url;
        } catch (error) {
          console.error(`Error getting image URL for ${card}:`, error);
        }
      }
      setCardImages(imageUrls);
    };

    fetchCardImages();
  }, []);

  const handleTarjetaChange = (cardName) => {
    setTarjetas((prevTarjetas) => ({
      ...prevTarjetas,
      [cardName]: !prevTarjetas[cardName],
    }));
  };

  const handleEditClick = (referenceId) => {
    const reference = references.find((ref) => ref.id === referenceId);
    setSelectedReferenceId(referenceId);
    setDescription(reference.description);
    setHours(reference.hours);

    // Manejo más seguro de tarjetas
    let tarjetasObj = {};
    if (Array.isArray(reference.tarjetas)) {
      reference.tarjetas.forEach((card) => (tarjetasObj[card] = true));
    } else if (
      typeof reference.tarjetas === "object" &&
      reference.tarjetas !== null
    ) {
      tarjetasObj = { ...reference.tarjetas };
    }
    setTarjetas(tarjetasObj);

    setOpen(true);
  };

  const handleDelete = async (referenceId) => {
    try {
      const referenceRef = doc(db, "reference", referenceId);
      await deleteDoc(referenceRef);
    } catch (error) {
      console.error("Error deleting the reference:", error);
    }
  };

  const handleSubmit = async () => {
    if (!description || !hours) {
      alert("Por favor, rellena todos los campos obligatorios");
      return;
    }
    const referenceRef = doc(db, "reference", selectedReferenceId);
    
    // Convierte el objeto de tarjetas a un array de tarjetas seleccionadas
    const tarjetasArray = Object.keys(tarjetas).filter(card => tarjetas[card]);
    
    const updateData = { description, hours, tarjetas: tarjetasArray };
    try {
      await updateDoc(referenceRef, updateData);
      handleClose();
    } catch (error) {
      console.error("Error updating reference:", error);
    }
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleHoursChange = (day, value) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: value,
    }));
  };

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = references.filter((reference) =>
      reference.name.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, references]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  useEffect(() => {
    const checkAuthAndFetchReferences = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();

        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        const { uid } = currentUser;
        setUid(uid);

        setLoading(true);
        const q = query(collection(db, "reference"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const referencesData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setReferences(referencesData);
          setLoading(false);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener referencias:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchReferences();
  }, [navigate]);

  const orderDays = (hours) => {
    if (!hours || typeof hours !== "object") {
      return [];
    }

    const daysOfWeek = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ];

    const dayMappings = {
      lunes: "Lunes",
      martes: "Martes",
      miercoles: "Miércoles",
      miércoles: "Miércoles",
      jueves: "Jueves",
      viernes: "Viernes",
      sabado: "Sábado",
      sábado: "Sábado",
      domingo: "Domingo",
      mon: "Lunes",
      tue: "Martes",
      wed: "Miércoles",
      thu: "Jueves",
      fri: "Viernes",
      sat: "Sábado",
      sun: "Domingo",
    };

    const orderedDays = daysOfWeek
      .map((day) => {
        const key = Object.keys(hours).find(
          (k) => dayMappings[k.toLowerCase()] === day || k === day
        );
        return key ? [day, hours[key]] : null;
      })
      .filter(Boolean);

    return orderedDays;
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "99%",
      border: "0.5px solid #ccc",
      margin: "25px auto",
      boxShadow: "0px 0px 5px 0px black",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "90vw",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
    },
    title: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "15px",
    },
    description: {
      fontWeight: "400",
      fontSize: "16px",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
      fontWeight: "400",
      marginBottom: "0px",
    },
    hours: {
      color: "dodgerblue",
      fontWeight: "400",
      marginBottom: "0px",
    },
    cards: {
      color: "lightseagreen",
      fontWeight: "400",
      marginBottom: "15px",
    },
    created: {
      fontWeight: "300",
      marginTop: "15px",
      marginBottom: "15px",
      fontSize: "12px",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    card: {
      width: "60px",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicaciones
            </Typography>
          </Container>
        </Toolbar>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "space-around",
            color: "black",
            borderTop: "1.5px solid white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editOffers" sx={{ color: "white" }}>
              <AttachMoneyIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar ofertas
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editReference" sx={{ color: "white" }}>
              <AccessTimeIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar referencias
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/editEvents" sx={{ color: "white" }}>
              <EventIcon />
            </IconButton>
            <Typography
              variant="caption"
              color={"white"}
              fontSize={9}
              align="center"
            >
              Editar eventos
            </Typography>
          </div>
        </Toolbar>
        <Toolbar sx={styles.search}>
          <input
            type="text"
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
            style={styles.inputS}
            placeholder="Buscar nombre de producto..."
          />
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 33, mb: 10 }}>
        <div>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {resultado.length > 0 ? (
                <List>
                  {resultado.map((reference) => (
                    <ListItem key={reference.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={reference.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography style={styles.name}>
                          {reference.name}
                        </Typography>
                      </Box>
                      <Typography style={styles.description}>
                        Descripción: {reference.description}
                      </Typography>
                      <Typography style={styles.hours}>
                        <Typography color={"black"}>Horarios:</Typography>
                        {reference.hours && (
                          <List>
                            {orderDays(reference.hours).map(([day, hours]) => (
                              <ListItem key={day}>
                                <Typography style={styles.hours}>
                                  {day}: {hours}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Typography>

                      <Typography style={styles.cards}>
                        <Typography color={"black"}>
                          Tarjetas aceptadas:
                        </Typography>{" "}
                        {reference.tarjetas?.length > 0 ? (
                          <Box display="flex" flexWrap="wrap">
                            {reference.tarjetas.map((card, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: 1,
                                  marginBottom: 1,
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={
                                    cardImages[card.toLowerCase()] ||
                                    "/path/to/default.png"
                                  }
                                  title={card}
                                  sx={{ width: 40, height: 40 }}
                                />
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <Typography color="gray">
                            No hay tarjetas disponibles
                          </Typography>
                        )}
                      </Typography>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleEditClick(reference.id)}
                      >
                        <Typography>Editar</Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{ mt: 2 }}
                        fullWidth
                        onClick={() => handleDelete(reference.id)}
                      >
                        <Typography>Eliminar</Typography>
                      </Button>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List>
                  {references.map((reference) => (
                    <ListItem key={reference.id} style={styles.listItem}>
                      <Box style={styles.contUser}>
                        <CardMedia
                          component="img"
                          image={reference.image}
                          title="Perfil"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            marginRight: 16,
                            border: "2.5px solid #312783",
                          }}
                        />
                        <Typography style={styles.name}>
                          {reference.name}
                        </Typography>
                      </Box>
                      <Typography style={styles.description}>
                        Descripción: {reference.description}
                      </Typography>
                      <Typography style={styles.hours}>
                        <Typography color={"black"}>Horarios:</Typography>
                        {reference.hours && (
                          <List>
                            {orderDays(reference.hours).map(([day, hours]) => (
                              <ListItem key={day}>
                                <Typography style={styles.hours}>
                                  {day}: {hours}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Typography>
                      <Typography style={styles.cards}>
                        <Typography color={"black"}>
                          Tarjetas aceptadas:
                        </Typography>{" "}
                        {reference.tarjetas?.length > 0 ? (
                          <Box display="flex" flexWrap="wrap">
                            {reference.tarjetas.map((card, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: 1,
                                  marginBottom: 1,
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={
                                    cardImages[card.toLowerCase()] ||
                                    "/path/to/default.png"
                                  }
                                  title={card}
                                  sx={{ width: 40, height: 40 }}
                                />
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <Typography color="gray">
                            No hay tarjetas disponibles
                          </Typography>
                        )}
                      </Typography>

                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleEditClick(reference.id)}
                      >
                        <Typography>Editar</Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{ mt: 2 }}
                        fullWidth
                        onClick={() => handleDelete(reference.id)}
                      >
                        <Typography>Eliminar</Typography>
                      </Button>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </div>
      </Container>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Editar Referencia</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="description"
            label="Descripción"
            type="text"
            fullWidth
            value={description}
            onChange={handleDescription}
          />
          <Typography color={"black"} sx={{ mt: 2 }}>
            Horarios:
          </Typography>
          {Object.keys(hours).map((day) => (
            <TextField
              key={day}
              margin="dense"
              label={`Horario ${day.charAt(0).toUpperCase() + day.slice(1)}`}
              type="text"
              fullWidth
              value={hours[day]}
              onChange={(e) => handleHoursChange(day, e.target.value)}
              sx={{ mb: 2 }}
            />
          ))}
          <Typography color={"black"} sx={{ mt: 2 }}>
            Tarjetas:
          </Typography>
          <FormGroup>
            {Object.keys(cardImages).map((card) => (
              <FormControlLabel
                key={card}
                control={
                  <Checkbox
                    checked={tarjetas[card] || false}
                    onChange={() => handleTarjetaChange(card)}
                  />
                }
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CardMedia
                      component="img"
                      image={cardImages[card] || "/path/to/default.png"}
                      title={card}
                      sx={{ width: 60, height: 60, marginRight: 1 }}
                    />
                    {card.charAt(0).toUpperCase() + card.slice(1)}
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>

      <BottomToolbar />
    </Container>
  );
};

export default EditReference;
