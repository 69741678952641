import React, { useEffect, useState } from "react";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Login from "./routes/login";
import Register from "./routes/register";
import Home from "./routes/home";
import Profile from "./routes/profile";
import Events from "./routes/events";
import Reference from "./routes/reference";
import Members from "./routes/members";
import Edit from "./routes/edit";
import Notifications from "./routes/notifications";
import Perfil from "./routes/perfil";
import SettingNotification from "./routes/settingNotification";
import Terms from "./routes/terms";
import Apps from "./routes/apps";
import Policy from "./routes/privacyPolicy";
import EditEvents from "./routes/editEvents";
import EditOffers from "./routes/editOffers";
import EditReference from "./routes/editReference";
import PublishOffer from "./routes/publishOffer";
import PublishEvents from "./routes/publishEvents";
import PublishReference from "./routes/publishReference";
import Atras from "./routes/atras";
import NotificationIcon from "./routes/NotificationIcon";
import User from "./routes/user";
import HeaderPerfil from "./routes/HeaderPerfil";
import Copy from "./routes/copy";
import BottomToolbar from "./routes/BottomToolbar";
import { isLoggedIn, endSession } from "./session";
import logo from "./imgs/logo192.png";

const Loading = () => (
  <div className="loading-container">
    <CardMedia
      className="loading-img"
      alt="Cargando..."
      component="img"
      image={logo}
    />
  </div>
);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <Router>
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/" element={user ? <Home handleLogout={handleLogout} /> : <Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home" element={<Home handleLogout={handleLogout} />} />
          <Route path="/events" element={<Events />} />
          <Route path="/reference" element={<Reference />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/editEvents" element={<EditEvents />} />
          <Route path="/editReference" element={<EditReference />} />
          <Route path="/copy" element={<Copy />} />
          <Route path="/atras" element={<Atras />} />
          <Route path="/HeaderPerfil" element={<HeaderPerfil />} />
          <Route path="/BotttomToolbar" element={<BottomToolbar />} />
          <Route path="/user" element={<User />} />
          <Route path="/NotificationIcon" element={<NotificationIcon />} />
          <Route path="/editOffers" element={<EditOffers />} />
          <Route path="/settingNotification" element={<SettingNotification />} />
          <Route path="/members" element={<Members />} />
          <Route path="/publishOffer" element={<PublishOffer />} />
          <Route path="/publishReference" element={<PublishReference />} />
          <Route path="/publishEvents" element={<PublishEvents />} />
          <Route path="/perfil/:uid" element={<Perfil />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/privacyPolicy" element={<Policy />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;