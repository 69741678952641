import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import { auth, db } from "../firebase"; // Asegúrate de importar tus configuraciones de Firebase
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import Atras from "./atras";

const PublishReference = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    date: {
      lunes: "",
      martes: "",
      miercoles: "",
      jueves: "",
      viernes: "",
      sabado: "",
      domingo: "",
    }, // Inicializa date como un objeto
  });
  const [cardImages, setCardImages] = useState({
    visa: null,
    mastercard: null,
    amex: null,
    diners: null,
    maestro: null,
    naranja: null,
    cabal: null,
    mercadopago: null,
  });
  const [publishSuccess, setPublishSuccess] = useState(false); // Estado para controlar el éxito
  const history = useNavigate(); // Hook para redireccionar

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchCardImages = async () => {
      const imageUrls = {};
      for (const card in cardImages) { // Access card names directly
        const storageRef = ref(storage, `cards/${card}.png`);
        try {
          const url = await getDownloadURL(storageRef);
          imageUrls[card] = url;
        } catch (error) {
          console.error(`Error getting image URL for ${card}:`, error);
        }
      }
      setCardImages(imageUrls); // Update state after fetching URLs
    };

    fetchCardImages();
  }, );

  const handlePublishReference = async () => {
    const user = auth.currentUser;

    if (!user) {
      console.error("No user is signed in");
      return;
    }

    const { uid } = user;
    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const {
        displayName: userName,
        profileImageUrl: imageProfile,
        uid: userId,
      } = userData;

      // Obtener los horarios de atención del formulario
      const hours = formData.date; // Suponiendo que tienes un campo "hours" en formData

      const selectedCards = Object.entries(tarjetas)
        .filter(([tarjeta, checked]) => checked)
        .map(([tarjeta, checked]) => tarjeta);

      await addDoc(collection(db, "reference"), {
        uid: userId,
        name: userName,
        image: imageProfile,
        description: formData.description,
        hours: hours, // Agregar los horarios de atención
        tarjetas: selectedCards, // Agregar la imagen de las tarjetas de crédito
        createdAt: serverTimestamp(),
      });
      setPublishSuccess(true); // Establecer el estado de éxito
    } else {
      console.error("User document does not exist");
    }
  };

  const [tarjetas, setTarjetas] = useState({
    visa: false,
    mastercard: false,
    amex: false,
    diners: false,
    maestro: false,
    naranja: false,
    cabal: false,
    mercadopago: false,
  });

  const handleTarjetaChange = (tarjeta) => {
    if (tarjetas[tarjeta]) {
      setTarjetas((prevTarjetas) => ({
        ...prevTarjetas,
        [tarjeta]: false,
      }));
    } else {
      setTarjetas((prevTarjetas) => ({
        ...prevTarjetas,
        [tarjeta]: true,
      }));
    }
  };

  const handleRedirectHome = () => {
    history("/reference");
  };

  const styles = {
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Referencia
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 14, mb: 10 }}>
        <Typography variant="h5" gutterBottom>
          Descripción de la Referencia
        </Typography>
        <TextField
          margin="dense"
          id="description"
          label="Descripción"
          type="text"
          fullWidth
          name="description"
          required
          rows={4}
          multiline
          value={formData.description}
          onChange={handleInputChange}
          sx={styles.textField}
        />
        <Typography variant="h5" gutterBottom>
          Horarios de Atención
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(formData.date).map(([dia, horario]) => (
            <Grid item xs={12} md={6} key={dia}>
              <TextField
                label={`Horario ${dia}`}
                fullWidth
                required
                value={horario} // Usa el valor del objeto de horarios
                onChange={(e) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    date: {
                      ...prevFormData.date,
                      [dia]: e.target.value,
                    },
                  }))
                }
              />
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Tarjetas Aceptadas
        </Typography>
        <FormGroup>
          {Object.entries(cardImages).map(
            (
              [card, imageUrl] // Use cardImages directly
            ) => (
              <FormControlLabel
                key={card}
                control={
                  <Checkbox
                    checked={tarjetas[card] || false} // Provide default value
                    onChange={() => handleTarjetaChange(card)}
                  />
                }
                label={
                  <div>
                    {imageUrl && <img src={imageUrl} alt={card} height="30" />}{" "}
                    {/* Display image if URL exists */}
                    <span>{card}</span>
                  </div>
                }
              />
            )
          )}
        </FormGroup>
        {publishSuccess && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>La oferta se ha publicado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Home
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handlePublishReference}
            color="primary"
          >
            Publicar
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default PublishReference;
