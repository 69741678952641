import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  TextField,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import Atras from "./atras";
import { updateUser, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import BottomToolbar from "./BottomToolbar";
import { useNavigate } from "react-router-dom";

const Edit = () => {
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const [point, setPoint] = useState("");
  const [publishSuccess, setPublishSuccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [publishError, setPublishError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePointChange = (event) => {
    setPoint(event.target.value);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    updateUser(user.uid, displayName, phone, point)
      .then(() => {
        setPublishSuccess(true);
        setPublishError(null);
        // Puedes agregar aquí una redirección u otra acción después de actualizar
        setTimeout(() => {
          setLoading(false);
          navigate(-1); // Navega a la página anterior
        }, 2000);
      })
      .catch((error) => {
        setPublishError(error);
        setPublishSuccess(false);
        setLoading(false);
        // Manejar el error, como mostrar un mensaje al usuario
        console.error("Error updating user:", error);
      });
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Editar perfil
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 16 }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            textAlign: "left",
            marginTop: "20px",
          }}
        >
          <TextField
            label="Nombre"
            variant="outlined"
            value={displayName}
            onChange={handleDisplayNameChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Teléfono"
            variant="outlined"
            value={phone}
            onChange={handlePhoneChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Localidad"
            variant="outlined"
            value={point}
            onChange={handlePointChange}
            fullWidth
            margin="normal"
          />
          {publishSuccess ? ( // Si publishSuccess es true
            <Box sx={{ textAlign: "center" }}>
              <Typography>Cambios guardados correctamente.</Typography>
              <Button onClick={() => navigate(-1)} color="primary">
                Volver atrás
              </Button>
            </Box>
          ) : ( // Si publishSuccess es false
            <Button
              sx={{ marginTop: 2 }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                "Guardar cambios"
              )}
            </Button>
          )}
        </Container>
      </Container>
      <BottomToolbar />
    </Container>
  );
};

export default Edit;
